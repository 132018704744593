import { useEffect } from "react";

function getAnchor(text) {
	return text
		.replace("https://www.youtube.com/watch?v=", "")
		.replace("https://youtube.com/watch?v=", "")
		.replace("www.youtube.com/watch?v=", "")
		.replace("youtube.com/watch?v=", "")
		.replace("https://www.youtu.be/", "")
		.replace("https://youtu.be/", "")
		.replace("www.youtu.be/", "")
		.replace("youtu.be/", "");
}

function YouTube(props) {
	useEffect(() => {
		var cookieValue = getCookie("cookieyes-consent");
		if (cookieValue) {
			var consents = cookieValue.split(",");
			var analyticsConsent = consents
				.find(function (c) {
					return c.startsWith("analytics");
				})
				.split(":")[1];
			var advertisementConsent = consents
				.find(function (c) {
					return c.startsWith("advertisement");
				})
				.split(":")[1];

			if (analyticsConsent === "no" || advertisementConsent === "no") {
				var containers = document.querySelectorAll(".youtube-cookies");
				if (containers.length > 0) {
					for (var i = 0; i < containers.length; i++) {
						updateContent(containers[i]);
					}
				}
			}
		}

		function updateContent(container) {
			container.textContent = "";
			var section = document.createElement("section");
			section.setAttribute("aria-live", "assertive");
			section.setAttribute("class", "cookie-alert");
			section.setAttribute("role", "alert");

			var p1 = document.createElement("p");
			p1.textContent =
				"Aby zobaczyć film, konieczna jest zgoda na używanie plików cookie analitycznych oraz reklamowych.";
			section.appendChild(p1);

			var p2 = document.createElement("p");
			p2.textContent =
				"Jeżeli wyrażasz zgodę, prosimy o zaakceptowanie plików cookie, a następnie odświeżenie strony.";
			section.appendChild(p2);

			var button = document.createElement("button");
			button.setAttribute("aria-label", "Zarządzaj zgodami na pliki cookie");
			button.setAttribute("class", "pd-btn pd-btn--primary");
			button.onclick = triggerConsentManager;
			button.textContent = "Zarządzaj zgodami na pliki cookie";
			section.appendChild(button);

			container.appendChild(section);
		}

		function triggerConsentManager() {
			var consentButton = document.querySelector(".cky-btn-revisit");
			if (consentButton) {
				consentButton.click();
			} else {
				alert("Przycisk zarządzania zgodami nie jest dostępny.");
			}
		}

		function getCookie(name) {
			var matches = document.cookie.match(
				new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"),
			);
			return matches ? decodeURIComponent(matches[1]) : undefined;
		}
	}, []);

	const videoSrc =
		"https://www.youtube.com/embed/" +
		getAnchor(props.src) +
		"?enablejsapi=1&modestbranding=1&controls=1&disablekb=1&rel=0&showinfo=0&fs=1&playsinline=1&color=white" +
		(props.autoplay ? "&autoplay=1" : "");

	const allowOptions =
		"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture";

	const containerClass = `youtube-full-width youtube-cookies ${
		props.article ? "youtube-article" : props.modal ? "youtube-modal" : ""
	}`;

	return (
		<>
			<div className={containerClass}>
				<iframe
					width="560"
					height="315"
					title={props.title}
					frameBorder="0"
					allow={allowOptions}
					allowFullScreen
					src={videoSrc}
					className="youtube-full-width__video"
				></iframe>
			</div>
		</>
	);
}

export default YouTube;
