import classNames from "classname";

export function Box(props) {
	return (
		<div
			className={classNames("box-with-shadow", {
				"box-with-shadow--primary": props.primary,
			})}
		>
			{props.children}
		</div>
	);
}

export default Box;
