import classNames from "classname";

export function AsideText(props) {
	return (
		<div
			className={classNames("aside-text", {
				"background-text": props.background,
				"background-text--light": props.lightBackground,
				"border-text": props.border,
			})}
		>
			{props.children}
		</div>
	);
}

export default AsideText;
