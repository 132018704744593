import { Link, animateScroll as scroll } from "react-scroll";
import classNames from "classname";

const polishLetters = {
	ą: "a",
	ć: "c",
	ę: "e",
	ł: "l",
	ń: "n",
	ó: "o",
	ś: "s",
	ź: "z",
	ż: "z",
};

const transformIdWithFormat = (text) => {
	let transformedText = text.toLowerCase();
	transformedText = [...transformedText].map((char) => polishLetters[char] || char).join("");
	return transformedText
		.replace(/\s+/g, "-")
		.replace(/[!?.,;:]+/g, "")
		.replace(/-+/g, "-");
};

export function LinkTo({ children, to, block, className, format }) {
	const generatedId = to
		? to
		: format && typeof children === "string"
		? transformIdWithFormat(children)
		: "";

	return (
		<span
			className={classNames("link-to", {
				block: block,
			})}
		>
			<Link
				to={generatedId}
				href={"#" + generatedId}
				spy={true}
				smooth={true}
				offset={-124}
				duration={500}
				className={className}
			>
				{children}
			</Link>
		</span>
	);
}

export default LinkTo;
