import { useRouter, withRouter } from "next/router";
import WCAG from "./WCAG";
import ActiveLink from "./ActiveLink";
import TranslatedMessage from "./TranslatedMessage";
import NavigationData from "../variables/variables.json";
import Search from "./blog/Search";
import NavigationScript from "./js/NavigationScript3";
import Link from "next/link";

export function Navigation(props) {
	const router = useRouter();

	// PROPS VARIABLE
	let propsMiasto = props.miasto;
	let propsPodatek = props.podatek;

	let propsType;
	let extendedUrl = "";
	let siteCode = router.query.siteCode;

	if (NavigationData.miasta[propsMiasto].index) {
		propsType = "/";
	} else if (NavigationData.miasta[propsMiasto].witryna) {
		propsType = "/witryna/";
		extendedUrl = "?siteCode=" + siteCode;
	} else {
		propsType = "/" + NavigationData.miasta[propsMiasto].name.url + "/";
	}

	const MenuItemSub = (props) => (
		<li className="pd-navigation__menu-item" role="menuitem" tabIndex="-1">
			<ActiveLink className="pd-navigation__link" activeClassName="active" href={props.href}>
				{props.children}
			</ActiveLink>
		</li>
	);

	const MenuItemSubNoLink = (props) => (
		<li className="pd-navigation__menu-item" role="menuitem" tabIndex="-1">
			<span className="pd-navigation__link">{props.children}</span>
		</li>
	);

	return (
		<>
			<nav className="pd-navigation pd-navigation--desktop" aria-label="Główna nawigacja">
				<nav className="pd-megamenu">
					<ul className="nav-menu">
						<li className="nav-item">
							<Link href="#">
								eUsługi<span className="chevron bottom"></span>
							</Link>
							<div className="sub-nav">
								<div className="sub-nav__wrapper">
									<ul className="sub-nav-group">
										<li>
											<span className="nav-item-title">
												<Link href="/podatek-od-nieruchomosci/">Podatek od nieruchomości</Link>
											</span>
										</li>
										<ul>
											<li>
												<Link href="/podatek-od-nieruchomosci/druk-in-1/">
													Informacja IN-1 dla osób fizycznych
												</Link>
											</li>
											<li>
												<Link href="/podatek-od-nieruchomosci/deklaracja-dn-1/">
													Deklaracja DN-1 dla osób prawnych
												</Link>
											</li>
										</ul>
									</ul>
									<ul className="sub-nav-group">
										<li>
											<span className="nav-item-title">Opłaty</span>
										</li>
										<ul>
											<li>
												<Link href="/oplata-za-gospodarowanie-odpadami-komunalnymi/">
													Opłata za gospodarowanie odpadami komunalnymi
												</Link>
											</li>
										</ul>
									</ul>
									<ul className="sub-nav-group">
										<li>
											<span className="nav-item-title">Wnioski</span>
										</li>
										<ul>
											<li>
												<Link href="/jak-otrzymac-numer-pesel/">
													Wniosek o nadanie numeru PESEL
												</Link>
											</li>
										</ul>
									</ul>
									<ul className="sub-nav-group">
										<li>
											<span className="nav-item-title">
												<Link href="/podatek-rolny/">Podatek rolny</Link>
											</span>
										</li>
										<li>
											<span className="nav-item-title">
												<Link href="/podatek-lesny/">Podatek leśny</Link>
											</span>
										</li>
										<li>
											<span className="nav-item-title">
												<Link href="/dt-1-deklaracja-na-podatek-od-srodkow-transportowych/">
													Podatek od środków transportowych
												</Link>
											</span>
										</li>
									</ul>
								</div>
							</div>
						</li>
						<li className="nav-item">
							<Link href="#">
								O nas<span className="chevron bottom"></span>
							</Link>
							<div className="sub-nav">
								<div className="sub-nav__wrapper">
									<ul className="sub-nav-group">
										<li>
											<span className="nav-item-title">
												<Link href="/dla-mieszkanca/">Dla Mieszkańca</Link>
											</span>
										</li>
										<p>
											Dowiedz się jak łatwo można złożyć druki podatkowe online w Twoim mieście lub
											gminie.
										</p>
									</ul>
									<ul className="sub-nav-group">
										<li>
											<span className="nav-item-title">
												<Link href="/dla-samorzadu/">Dla Samorządu</Link>
											</span>
										</li>
										<p>
											Dowiedz się co zyskuje samorząd i co zyskują podatnicy dzięki Przyjaznym
											Deklaracjom.
										</p>
									</ul>
									<ul className="sub-nav-group">
										<li>
											<span className="nav-item-title">
												<Link href="/jak-to-dziala/">Jak to działa?</Link>
											</span>
										</li>
										<p>Rozliczenia z Twoim Urzędem nigdy wcześniej nie były tak proste.</p>
									</ul>
								</div>
							</div>
						</li>
						<li className="nav-item">
							<Link href="#">
								Poradniki<span className="chevron bottom"></span>
							</Link>
							<div className="sub-nav">
								<div className="sub-nav__wrapper">
									<ul className="sub-nav-group">
										<li>
											<span className="nav-item-title">
												<Link href="/abc-podatkow/">ABC Podatków</Link>
											</span>
										</li>
										<p>Znajdź odpowiedzi na najbardziej nurtujące pytania o podatkach lokalnych.</p>
									</ul>
									<ul className="sub-nav-group">
										<li>
											<span className="nav-item-title">
												<Link href="/wazne-terminy/">Ważne terminy</Link>
											</span>
										</li>
										<p>Kluczowe daty i terminy płatności podatkowych.</p>
									</ul>
									<ul className="sub-nav-group">
										<li>
											<span className="nav-item-title">
												<Link href="/plik-dek/">Plik DEK</Link>
											</span>
										</li>
										<p>Czym jest i dlaczego warto go mieć?</p>
									</ul>
									<ul className="sub-nav-group">
										<li>
											<span className="nav-item-title">
												<Link href="/najczesciej-zadawane-pytania-faq/">
													Najczęściej zadawane pytania (FAQ)
												</Link>
											</span>
										</li>
										<p>
											Tutaj znajdziesz odpowiedzi na pytania o przygotowanie i składanie deklaracji
											podatkowych online, wykorzystując algorytmy do doboru stawek i obliczania
											podatków.
										</p>
									</ul>
								</div>
							</div>
						</li>
						<li className="nav-item">
							<Link href="/aktualnosci/">Aktualności</Link>
						</li>
						<li className="nav-item">
							<Link href="/kontakt/">Kontakt</Link>
						</li>
					</ul>
				</nav>

				<WCAG />

				{NavigationData.miasta[propsMiasto].search.available && (
					<ul
						className="pd-navigation__menu pd-navigation__menu__wcag"
						role="menubar"
						aria-label="functions"
						id="appmenu"
					>
						<li
							className="pd-navigation__menu-item pd-navigation__search"
							role="menuitem"
							tabIndex="-1"
							aria-expanded="false"
						>
							<Search name="search-desktop" />
						</li>
					</ul>
				)}
			</nav>

			<nav className="pd-navigation pd-navigation--mobile" aria-label="Główna nawigacja">
				<button className="pd-navigation__button__mobile" aria-label="Menu">
					<span className="hamburger"></span>
				</button>
				<ul className="pd-navigation__menu" role="menu" aria-label="functions" id="appmenu">
					{NavigationData.miasta[propsMiasto].navigation.map((navigation, index) =>
						navigation.submenu.available ? (
							<li
								className="pd-navigation__menu-item"
								role="menuitem"
								aria-haspopup="true"
								tabIndex="0"
								aria-expanded="false"
								key={index}
							>
								<span className="pd-navigation__link">
									<TranslatedMessage id={navigation.translatedMessage} />
									<span className="chevron bottom"></span>
								</span>
								<ul className="pd-navigation__submenu" role="menu">
									{navigation.submenu.list.map((list, index) =>
										list.submenu ? (
											<li
												className="pd-navigation__menu-item hasSubMenu"
												role="menuitem"
												tabIndex="-1"
												aria-haspopup="true"
												aria-expanded="false"
												key={index}
											>
												<span className="pd-navigation__link">
													<TranslatedMessage id={list.translatedMessage} />
													<span className="chevron right"></span>
												</span>

												<ul className="pd-navigation__submenu subSubMenu" role="menu">
													{list.submenu.map((list, index) => (
														<MenuItemSub
															href={propsType + list.shortName + extendedUrl}
															key={index}
														>
															<TranslatedMessage id={list.translatedMessage} />
														</MenuItemSub>
													))}
												</ul>
											</li>
										) : (
											<MenuItemSub href={propsType + list.shortName + extendedUrl} key={index}>
												<TranslatedMessage id={list.translatedMessage} />
											</MenuItemSub>
										),
									)}
								</ul>
							</li>
						) : (
							<MenuItemSub href={propsType + navigation.shortName + extendedUrl} key={index}>
								<TranslatedMessage id={navigation.translatedMessage} />
							</MenuItemSub>
						),
					)}
				</ul>

				<WCAG />

				{NavigationData.miasta[propsMiasto].search.available && (
					<ul
						className="pd-navigation__menu pd-navigation__menu__wcag"
						role="menubar"
						aria-label="functions"
						id="appmenu"
					>
						<li
							className="pd-navigation__menu-item pd-navigation__search"
							role="menuitem"
							tabIndex="-1"
							aria-expanded="false"
						>
							<Search name="search-mobile" />
						</li>
					</ul>
				)}
			</nav>

			<div className="pd-navigation__overflow"></div>

			<NavigationScript />
		</>
	);
}

export default withRouter(Navigation);
