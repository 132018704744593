import Logo from "./Logo";
import Navigation from "./Navigation2";

import HeaderScript from "./js/HeaderScript";

const Header = (props) => {
	// PROPS VARIABLE
	let propsMiasto = props.miasto;
	let propsPodatek = props.podatek;

	return (
		<>
			<header className="header">
				<div className="header__container">
					<Logo miasto={propsMiasto} />
					{/* <LanguageSwitcher /> */}

					<div className="header__wrapper">
						<Navigation miasto={propsMiasto} />
					</div>
				</div>
			</header>

			<HeaderScript />
		</>
	);
};

export default Header;
