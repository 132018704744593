import React, { useState } from "react";
import Modal from "./Modal";
import Variables from "../variables/variables.json";

const FloatVideo = (props) => {
	const [isExpanded, setIsExpanded] = useState(true);
	const [selectedImage, setSelectedImage] = useState(null);

	let propsConfig = props.config;

	const toggleExpand = () => {
		setIsExpanded(!isExpanded);
	};

	const openModal = (image) => {
		setSelectedImage(image);
	};

	const closeModal = () => {
		setSelectedImage(null);
	};

	return (
		<>
			<div
				className={`floatVideo ${isExpanded ? "expanded" : ""}`}
				aria-expanded={isExpanded}
				aria-label="Rozwiń przycisk"
			>
				<button
					className="floatVideo-action"
					onClick={toggleExpand}
					aria-label={!isExpanded ? Variables.floatVideo[propsConfig].text : "Zamknij okno"}
				>
					{!isExpanded ? (
						<>
							<img
								src="https://przyjazne-deklaracje.pl/resources/images/icons/general/files/icon-play-video.svg"
								alt=""
							/>
							{Variables.floatVideo[propsConfig].text}
						</>
					) : (
						<>
							<img
								src="https://przyjazne-deklaracje.pl/resources/images/icons/general/files/icon-close-modal.svg"
								alt=""
							/>
							Zamknij
						</>
					)}
				</button>
				{isExpanded && (
					<div className="expandedContent" role="dialog" aria-modal="true">
						<h2>{Variables.floatVideo[propsConfig].title}</h2>
						<div className="imageGrid">
							{Variables.floatVideo[propsConfig].images.map((image) => (
								<div
									className="imageWrapper"
									key={image.id}
									onClick={() => openModal(image)}
									role="button"
									aria-label={`Otwórz modal z tytułem ${image.modalTitle}`}
								>
									<div className="imageShadow">
										<img
											className="imageIcon"
											src="https://przyjazne-deklaracje.pl/resources/images/icons/general/files/icon-play-video.svg"
										/>
									</div>
									<img src={image.src} alt={`Image ${image.id}`} className="image" />
								</div>
							))}
						</div>
					</div>
				)}
			</div>
			{selectedImage && <Modal image={selectedImage} closeModal={closeModal} />}
		</>
	);
};

export default FloatVideo;
