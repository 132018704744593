const InputMaterial = (props) => {
	return (
		<div className="pd-material__group">
			<input
				className="pd-material__input pd-material__input__input"
				type={props.type}
				required={props.required}
				name={props.name}
				value={props.value}
				placeholder={props.placeholder}
				id={props.name}
				onChange={props.onChange}
				onFocus={props.onFocus}
				onKeyPress={props.onKeyPress}
			/>
			<label className="pd-material__input__label" htmlFor={props.name}>
				{props.children}
			</label>
		</div>
	);
};

export default InputMaterial;
