import { useEffect, useState } from "react";

export const HighContrast = () => {
	const [theme, setTheme] = useState("false");
	const [componentMounted, setComponentMounted] = useState(false);

	const setHighContrastCookie = (highContrast) => {
		const cookieName = "applicationContext=";
		const cookieValue = document.cookie
			.split("; ")
			.find((row) => row.startsWith(cookieName))
			?.substring(cookieName.length);

		let appContext = cookieValue ? JSON.parse(decodeURIComponent(cookieValue)) : {};
		appContext.highContrast = highContrast;

		const expirationDate = new Date();
		expirationDate.setDate(expirationDate.getDate() + 7);
		const expires = "expires=" + expirationDate.toUTCString();
		document.cookie =
			cookieName + encodeURIComponent(JSON.stringify(appContext)) + ";" + expires + ";path=/";
	};

	const toggleTheme = () => {
		const newTheme = theme === "false" ? "true" : "false";
		setHighContrastCookie(newTheme === "true");
		setTheme(newTheme);
		document.body.classList.toggle("pd-highcontrast");
	};

	useEffect(() => {
		const cookieName = "applicationContext=";
		const cookieValue = document.cookie
			.split("; ")
			.find((row) => row.startsWith(cookieName))
			?.substring(cookieName.length);

		const appContext = cookieValue ? JSON.parse(decodeURIComponent(cookieValue)) : {};

		if (appContext.highContrast) {
			setTheme(appContext.highContrast ? "true" : "false");
			document.body.classList.add("pd-highcontrast");
		} else {
			setTheme("false");
			document.body.classList.remove("pd-highcontrast");
		}
		setComponentMounted(true);
	}, []);

	return [theme, toggleTheme, componentMounted];
};
