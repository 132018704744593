import { useRouter } from "next/router";
import NazwaMiasta from "../../variables/variables.json";

export function City(props) {
	const router = useRouter();

	// let city = router.query.city
	// let siteCode = router.query.siteCode

	let Miasto = "GW_KONSKOWOLA";

	// if (siteCode === "5badb847-b841-453a-8039-662f2c6ee193") {
	//   Miasto = "GM_OSTROLEKA"
	// } else if (siteCode === "5badb847-b841-453a-8039-123") {
	//   Miasto = "GW_KONSKOWOLA"
	// }

	// if (city === "ostroleka") {
	//   Miasto = "GM_OSTROLEKA"
	// } else if (city === "konskowola") {
	//   Miasto = "GW_KONSKOWOLA"
	// }

	// let Miasto = router.asPath.substring(
	//   router.asPath.lastIndexOf("/") + 1,
	//   router.asPath.length
	// )

	return <span>{NazwaMiasta.miasta[Miasto].name[props.name]}</span>;
}

export default City;
