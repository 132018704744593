import React from "react";
import YouTube from "./mdx/YouTube";

const Modal = ({ image, closeModal }) => {
	return (
		<div
			className="modalOverlay"
			onClick={closeModal}
			role="dialog"
			aria-modal="true"
			aria-labelledby="modal-title"
		>
			<div className="modalContent" onClick={(e) => e.stopPropagation()}>
				<div className="modal-header">
					<h2 id="modal-title" className="modalTitle">
						{image.modalTitle}
					</h2>
					<button className="modalCloseButton" onClick={closeModal} aria-label="Zamknij"></button>
				</div>
				<YouTube modal title={image.modalTitle} src={image.modalUrl} autoplay />
			</div>
		</div>
	);
};

export default Modal;
