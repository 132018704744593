import { useEffect, useState } from "react";

export const FontButton = () => {
	const [fontSize, setFontSize] = useState(17);
	const [componentMounted, setComponentMounted] = useState(false);

	const setFontSizeCookie = (size) => {
		const cookieName = "applicationContext=";
		const cookieValue = document.cookie
			.split("; ")
			.find((row) => row.startsWith(cookieName))
			?.substring(cookieName.length);

		let appContext = cookieValue ? JSON.parse(decodeURIComponent(cookieValue)) : {};
		appContext.fontSize = size;

		const expirationDate = new Date();
		expirationDate.setDate(expirationDate.getDate() + 7);
		const expires = "expires=" + expirationDate.toUTCString();
		document.cookie =
			cookieName + encodeURIComponent(JSON.stringify(appContext)) + ";" + expires + ";path=/";
	};

	function removeClass() {
		const startsWith = "font-";
		const classes = document.documentElement.className.split(" ").filter(function (v) {
			return v.lastIndexOf(startsWith, 0) !== 0;
		});
		document.documentElement.className = classes.join(" ").trim();
	}

	function fontSizeReset() {
		setFontSize(17);
		setFontSizeCookie(17);
		document.documentElement.classList.add("font-17");
	}

	function fontSizeChange(step) {
		const currentSize = parseInt(fontSize, 10);
		const newSize = currentSize + step;
		if (newSize >= 11 && newSize <= 35) {
			setFontSize(newSize);
			setFontSizeCookie(newSize);
			removeClass();
			document.documentElement.classList.add("font-" + newSize);
		}
	}

	function fontSizeIncrease() {
		fontSizeChange(3);
	}

	function fontSizeDecrease() {
		fontSizeChange(-3);
	}

	useEffect(() => {
		const cookieName = "applicationContext=";
		const cookieValue = document.cookie
			.split("; ")
			.find((row) => row.startsWith(cookieName))
			?.substring(cookieName.length);

		const appContext = cookieValue ? JSON.parse(decodeURIComponent(cookieValue)) : {};
		const localFontSize = appContext.fontSize || 17;

		setFontSize(localFontSize);
		document.documentElement.classList.add("font-" + localFontSize);
		setComponentMounted(true);
	}, []);

	return [
		fontSize,
		typeof fontSize === "number" ? fontSize.toFixed(0) : "17",
		fontSizeReset,
		fontSizeIncrease,
		fontSizeDecrease,
		componentMounted,
	];
};
