import classNames from "classname";
import MDX from "./MDX";
import TranslatedMessage from "./TranslatedMessage";

const Section = (props) => {
	return (
		<>
			<section
				id={props.id}
				className={classNames("pd-section", {
					"pd-section--lightdark": props.lightdark,
					"pd-section--lighterdark": props.lighterdark,
					"pd-section--primary": props.primary,
					"pd-section--center": props.center,
					"pd-section--small--top": props.smallTop,
					"pd-section--large": props.large,
					"pd-section--no-panel": props.noPanel,
					"pd-section--max-width": props.maxWidth,
					"pd-section--big-padding-top": props.bigPaddingTop,
					"pd-section--title-left": props.titleLeft,
				})}
			>
				<div className="pd-section__inner-wrap">
					{props.title && (
						<h2 className="pd-section__title">
							<TranslatedMessage id={props.title} />
						</h2>
					)}

					{props.mdx ? <MDX>{props.children}</MDX> : [props.children]}
				</div>
			</section>
		</>
	);
};

export default Section;
