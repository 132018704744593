const polishLetters = {
	ą: "a",
	ć: "c",
	ę: "e",
	ł: "l",
	ń: "n",
	ó: "o",
	ś: "s",
	ź: "z",
	ż: "z",
};

const transformIdWithFormat = (text) => {
	let transformedText = text.toLowerCase();
	transformedText = [...transformedText].map((char) => polishLetters[char] || char).join("");
	return transformedText
		.replace(/\s+/g, "-")
		.replace(/[!?.,;:]+/g, "")
		.replace(/-+/g, "-");
};

export function H3({ children, id, format }) {
	const generatedId = id
		? id
		: format && typeof children === "string"
		? transformIdWithFormat(children)
		: "";

	return (
		<h3 className="pd-panel__header--h3" id={generatedId}>
			{children}
		</h3>
	);
}

export default H3;
